import React from "react"
import Helmet from "react-helmet"
import styles from "./stylesheet.module.css"
import favicon from "../resources/favicon.png"
import gsfIllustrationCircle from "../resources/gsf_illustration_circle.png"
import i18n from "../resources/i18n"

const debugIOS = false;
const debugAndroid = false;
const debugIOSSafari = false;
const debugLanguage = null;

export default class index extends React.Component {

    render() {
        this.initI18n();

        return (
            <div>
                <Helmet>
                    <link rel="shortcut icon" type="image/png" href={favicon} />
                </Helmet>
                {this.isBrowser() && (
                    <div className={styles.wrapper}>
                        {(this.isAndroid() || this.isIOS()) && (
                            <table id={styles.banner} className={this.isRTL() ? styles.rtl : null}>
                                <tbody>
                                    <tr>
                                        <td id={styles.description}>
                                            <div id={styles.title} className={this.isRTL() ? styles.rtl : null}>{this.getString("hp_panorama")}</div>
                                            {(this.isAndroid()) && (
                                                <div id={styles.subtitle} className={this.isRTL() ? styles.rtl : null}>{this.getString("install_google_play")}</div>
                                            )}
                                            {(this.isIOS()) && (
                                                <div id={styles.subtitle} className={this.isRTL() ? styles.rtl : null}>{this.getString("install_app_store")}</div>
                                            )}
                                        </td>
                                        <td id={styles.action}>
                                            {(this.isAndroid()) && (
                                                // <a id={styles.button} className={this.isRTL() ? styles.rtl : null} href={window.location.href.replace(window.location.protocol, "intent:") + "#Intent;scheme=" + window.location.protocol.slice(0, -1) + ";package=com.hp.impulse.panorama;end"}>{this.getString("open")}</a>
                                                <a id={styles.button} className={this.isRTL() ? styles.rtl : null} href='intent://eventspanorama.sprocketprinters.com/#Intent;scheme=https;package=com.hp.impulse.panorama;end'>{this.getString("open")}</a>
                                            )}
                                            {(this.isIOS()) && (
                                                <a id={styles.button} className={this.isRTL() ? styles.rtl : null} href='https://apps.apple.com/us/app/hp-sprocket-panorama/id1630706219'>{this.getString("get")}</a>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        <div id={styles.content}>
                            {(this.isIOS() && this.isIOSSafari()) && (
                                <div id={styles.topText} className={this.isRTL() ? styles.rtl : null}>{this.getString("already_have")}<br />{this.getString("swipe_down_start")}<span id={styles.highlight}>{this.getString("swipe_down_highlight")}</span>{this.getString("swipe_down_end")}</div>
                            )}
                            <div id={(this.isIOS() || this.isAndroid()) ? styles.middleBar : styles.middleBarBig}>
                                <img id={styles.centerImage} alt={this.getString("hp_panorama")} src={gsfIllustrationCircle} />
                                <div id={this.getString("you_were_invited").length < 90 ? styles.centerText : styles.centerTextSmaller} className={this.isRTL() ? styles.rtl : null}>{this.getString("you_were_invited")}</div>
                            </div>
                            <div id={styles.bottomBar}>
                                <div id={(this.isIOS() || this.isAndroid()) ? styles.bottomText : styles.bottomTextBig} className={this.isRTL() ? styles.rtl : null}>{this.getString("dont_have")}</div>
                                <div id={styles.badgeBar} className={this.isRTL() ? styles.rtl : null}>
                                    {(this.isIOS() || (!this.isIOS() && !this.isAndroid())) && (
                                        <a href='https://itunes.apple.com/us/app/hp-sprocket/id1132331237'>
                                            <img id={styles.appleBadge} alt={this.getString("install_app_store")} src={this.getImage("apple_store_badge.svg")} />
                                        </a>
                                    )}
                                    {(!this.isIOS() && !this.isAndroid()) && (
                                        <div id={styles.badgeSeparator} />
                                    )}
                                    {(this.isAndroid() || (!this.isIOS() && !this.isAndroid())) && (
                                        <a href='https://play.google.com/store/apps/details?id=com.hp.impulse.panorama'>
                                            <img id={styles.googleBadge} alt={this.getString("install_google_store")} src={this.getImage("google_store_badge.svg")} />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    isBrowser() {
        return (typeof window !== `undefined` && typeof navigator !== `undefined`);
    }

    initI18n() {
        if (debugLanguage) {
            this.i18n = new i18n(debugLanguage);
        } else if (this.isBrowser()) {
            this.i18n = new i18n(navigator.language);
        }
    }

    getString(id) {
        if (this.isBrowser()) {
            return this.i18n.getString(id);
        }
    }

    getImage(id) {
        if (this.isBrowser()) {
            return this.i18n.getImage(id);
        }
    }

    isRTL() {
        if (this.isBrowser()) {
            return this.i18n.getSetting("rtl");
        }
    }

    isIOS() {
        if (debugIOS) {
            return true;
        } else if (this.isBrowser()) {
            return (navigator.userAgent.indexOf('iPad') !== -1 || navigator.userAgent.indexOf('iPhone') !== -1);
        }
    }

    isAndroid() {
        if (debugAndroid) {
            return true;
        } else if (this.isBrowser()) {
            return (navigator.userAgent.indexOf('Android') !== -1);
        }
    }

    isIOSSafari() {
        if (debugIOSSafari) {
            return true;
        } else if (this.isBrowser()) {
            return (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('CriOS') === -1);
        }
    }
}