import enStrings from "./en/strings.json"
import enSettings from "./en/settings.json"
import enAppleStoreBadge from "./en/apple_store_badge.svg"
import enGoogleStoreBadge from "./en/google_store_badge.svg"

import arStrings from "./ar/strings.json"
import arSettings from "./ar/settings.json"
import arAppleStoreBadge from "./ar/apple_store_badge.svg"
import arGoogleStoreBadge from "./ar/google_store_badge.svg"

import csStrings from "./cs/strings.json"
import csSettings from "./cs/settings.json"
import csAppleStoreBadge from "./cs/apple_store_badge.svg"
import csGoogleStoreBadge from "./cs/google_store_badge.svg"

import daStrings from "./da/strings.json"
import daSettings from "./da/settings.json"
import daAppleStoreBadge from "./da/apple_store_badge.svg"
import daGoogleStoreBadge from "./da/google_store_badge.svg"

import deStrings from "./de/strings.json"
import deSettings from "./de/settings.json"
import deAppleStoreBadge from "./de/apple_store_badge.svg"
import deGoogleStoreBadge from "./de/google_store_badge.svg"

import elStrings from "./el/strings.json"
import elSettings from "./el/settings.json"
import elAppleStoreBadge from "./el/apple_store_badge.svg"
import elGoogleStoreBadge from "./el/google_store_badge.svg"

import esESStrings from "./es-ES/strings.json"
import esESSettings from "./es-ES/settings.json"
import esESAppleStoreBadge from "./es-ES/apple_store_badge.svg"
import esESGoogleStoreBadge from "./es-ES/google_store_badge.svg"

import esMXStrings from "./es-MX/strings.json"
import esMXSettings from "./es-MX/settings.json"
import esMXAppleStoreBadge from "./es-MX/apple_store_badge.svg"
import esMXGoogleStoreBadge from "./es-MX/google_store_badge.svg"

import etStrings from "./et/strings.json"
import etSettings from "./et/settings.json"
import etAppleStoreBadge from "./et/apple_store_badge.svg"
import etGoogleStoreBadge from "./et/google_store_badge.svg"

import fiStrings from "./fi/strings.json"
import fiSettings from "./fi/settings.json"
import fiAppleStoreBadge from "./fi/apple_store_badge.svg"
import fiGoogleStoreBadge from "./fi/google_store_badge.svg"

import frCAStrings from "./fr-CA/strings.json"
import frCASettings from "./fr-CA/settings.json"
import frCAAppleStoreBadge from "./fr-CA/apple_store_badge.svg"
import frCAGoogleStoreBadge from "./fr-CA/google_store_badge.svg"

import frFRStrings from "./fr-FR/strings.json"
import frFRSettings from "./fr-FR/settings.json"
import frFRAppleStoreBadge from "./fr-FR/apple_store_badge.svg"
import frFRGoogleStoreBadge from "./fr-FR/google_store_badge.svg"

import heStrings from "./he/strings.json"
import heSettings from "./he/settings.json"
import heAppleStoreBadge from "./he/apple_store_badge.svg"
import heGoogleStoreBadge from "./he/google_store_badge.svg"

import huStrings from "./hu/strings.json"
import huSettings from "./hu/settings.json"
import huAppleStoreBadge from "./hu/apple_store_badge.svg"
import huGoogleStoreBadge from "./hu/google_store_badge.svg"

import idStrings from "./id/strings.json"
import idSettings from "./id/settings.json"
import idAppleStoreBadge from "./id/apple_store_badge.svg"
import idGoogleStoreBadge from "./id/google_store_badge.svg"

import itStrings from "./it/strings.json"
import itSettings from "./it/settings.json"
import itAppleStoreBadge from "./it/apple_store_badge.svg"
import itGoogleStoreBadge from "./it/google_store_badge.svg"

import jaStrings from "./ja/strings.json"
import jaSettings from "./ja/settings.json"
import jaAppleStoreBadge from "./ja/apple_store_badge.svg"
import jaGoogleStoreBadge from "./ja/google_store_badge.svg"

import ltStrings from "./lt/strings.json"
import ltSettings from "./lt/settings.json"
import ltAppleStoreBadge from "./lt/apple_store_badge.svg"
import ltGoogleStoreBadge from "./lt/google_store_badge.svg"

import lvStrings from "./lv/strings.json"
import lvSettings from "./lv/settings.json"
import lvAppleStoreBadge from "./lv/apple_store_badge.svg"
import lvGoogleStoreBadge from "./lv/google_store_badge.svg"

import nbStrings from "./nb/strings.json"
import nbSettings from "./nb/settings.json"
import nbAppleStoreBadge from "./nb/apple_store_badge.svg"
import nbGoogleStoreBadge from "./nb/google_store_badge.svg"

import nlStrings from "./nl/strings.json"
import nlSettings from "./nl/settings.json"
import nlAppleStoreBadge from "./nl/apple_store_badge.svg"
import nlGoogleStoreBadge from "./nl/google_store_badge.svg"

import plStrings from "./pl/strings.json"
import plSettings from "./pl/settings.json"
import plAppleStoreBadge from "./pl/apple_store_badge.svg"
import plGoogleStoreBadge from "./pl/google_store_badge.svg"

import ptBRStrings from "./pt-BR/strings.json"
import ptBRSettings from "./pt-BR/settings.json"
import ptBRAppleStoreBadge from "./pt-BR/apple_store_badge.svg"
import ptBRGoogleStoreBadge from "./pt-BR/google_store_badge.svg"

import ptPTStrings from "./pt-PT/strings.json"
import ptPTSettings from "./pt-PT/settings.json"
import ptPTAppleStoreBadge from "./pt-PT/apple_store_badge.svg"
import ptPTGoogleStoreBadge from "./pt-PT/google_store_badge.svg"

import ruStrings from "./ru/strings.json"
import ruSettings from "./ru/settings.json"
import ruAppleStoreBadge from "./ru/apple_store_badge.svg"
import ruGoogleStoreBadge from "./ru/google_store_badge.svg"

import skStrings from "./sk/strings.json"
import skSettings from "./sk/settings.json"
import skAppleStoreBadge from "./sk/apple_store_badge.svg"
import skGoogleStoreBadge from "./sk/google_store_badge.svg"

import svStrings from "./sv/strings.json"
import svSettings from "./sv/settings.json"
import svAppleStoreBadge from "./sv/apple_store_badge.svg"
import svGoogleStoreBadge from "./sv/google_store_badge.svg"

import thStrings from "./th/strings.json"
import thSettings from "./th/settings.json"
import thAppleStoreBadge from "./th/apple_store_badge.svg"
import thGoogleStoreBadge from "./th/google_store_badge.svg"

import trStrings from "./tr/strings.json"
import trSettings from "./tr/settings.json"
import trAppleStoreBadge from "./tr/apple_store_badge.svg"
import trGoogleStoreBadge from "./tr/google_store_badge.svg"

import zhCNStrings from "./zh-CN/strings.json"
import zhCNSettings from "./zh-CN/settings.json"
import zhCNAppleStoreBadge from "./zh-CN/apple_store_badge.svg"
import zhCNGoogleStoreBadge from "./zh-CN/google_store_badge.svg"

import zhHKStrings from "./zh-HK/strings.json"
import zhHKSettings from "./zh-HK/settings.json"
import zhHKAppleStoreBadge from "./zh-HK/apple_store_badge.svg"
import zhHKGoogleStoreBadge from "./zh-HK/google_store_badge.svg"

import zhTWStrings from "./zh-TW/strings.json"
import zhTWSettings from "./zh-TW/settings.json"
import zhTWAppleStoreBadge from "./zh-TW/apple_store_badge.svg"
import zhTWGoogleStoreBadge from "./zh-TW/google_store_badge.svg"

const appleStoreBadge = "apple_store_badge.svg";
const googleStoreBadge = "google_store_badge.svg";

class i18n {
    constructor(language) {
        if (language.includes("ar")) {
            this.resources = {strings:arStrings, settings:arSettings, images:{[appleStoreBadge]: arAppleStoreBadge, [googleStoreBadge]: arGoogleStoreBadge}}
        } else if (language.includes("cs")) {
            this.resources = {strings:csStrings, settings:csSettings, images:{[appleStoreBadge]: csAppleStoreBadge, [googleStoreBadge]: csGoogleStoreBadge}}
        } else if (language.includes("da")) {
            this.resources = {strings:daStrings, settings:daSettings, images:{[appleStoreBadge]: daAppleStoreBadge, [googleStoreBadge]: daGoogleStoreBadge}}
        } else if (language.includes("de")) {
            this.resources = {strings:deStrings, settings:deSettings, images:{[appleStoreBadge]: deAppleStoreBadge, [googleStoreBadge]: deGoogleStoreBadge}}
        } else if (language.includes("el")) {
            this.resources = {strings:elStrings, settings:elSettings, images:{[appleStoreBadge]: elAppleStoreBadge, [googleStoreBadge]: elGoogleStoreBadge}}
        } else if (language.includes("es-MX")) {
            this.resources = {strings:esMXStrings, settings:esMXSettings, images:{[appleStoreBadge]: esMXAppleStoreBadge, [googleStoreBadge]: esMXGoogleStoreBadge}}
        } else if (language.includes("es")) {
            this.resources = {strings:esESStrings, settings:esESSettings, images:{[appleStoreBadge]: esESAppleStoreBadge, [googleStoreBadge]: esESGoogleStoreBadge}}
        } else if (language.includes("et")) {
            this.resources = {strings:etStrings, settings:etSettings, images:{[appleStoreBadge]: etAppleStoreBadge, [googleStoreBadge]: etGoogleStoreBadge}}
        } else if (language.includes("fi")) {
            this.resources = {strings:fiStrings, settings:fiSettings, images:{[appleStoreBadge]: fiAppleStoreBadge, [googleStoreBadge]: fiGoogleStoreBadge}}
        } else if (language.includes("fr-CA")) {
            this.resources = {strings:frCAStrings, settings:frCASettings, images:{[appleStoreBadge]: frCAAppleStoreBadge, [googleStoreBadge]: frCAGoogleStoreBadge}}
        } else if (language.includes("fr")) {
            this.resources = {strings:frFRStrings, settings:frFRSettings, images:{[appleStoreBadge]: frFRAppleStoreBadge, [googleStoreBadge]: frFRGoogleStoreBadge}}
        } else if (language.includes("he")) {
            this.resources = {strings:heStrings, settings:heSettings, images:{[appleStoreBadge]: heAppleStoreBadge, [googleStoreBadge]: heGoogleStoreBadge}}
        } else if (language.includes("hu")) {
            this.resources = {strings:huStrings, settings:huSettings, images:{[appleStoreBadge]: huAppleStoreBadge, [googleStoreBadge]: huGoogleStoreBadge}}
        } else if (language.includes("id")) {
            this.resources = {strings:idStrings, settings:idSettings, images:{[appleStoreBadge]: idAppleStoreBadge, [googleStoreBadge]: idGoogleStoreBadge}}
        } else if (language.includes("it")) {
            this.resources = {strings:itStrings, settings:itSettings, images:{[appleStoreBadge]: itAppleStoreBadge, [googleStoreBadge]: itGoogleStoreBadge}}
        } else if (language.includes("ja")) {
            this.resources = {strings:jaStrings, settings:jaSettings, images:{[appleStoreBadge]: jaAppleStoreBadge, [googleStoreBadge]: jaGoogleStoreBadge}}
        } else if (language.includes("lt")) {
            this.resources = {strings:ltStrings, settings:ltSettings, images:{[appleStoreBadge]: ltAppleStoreBadge, [googleStoreBadge]: ltGoogleStoreBadge}}
        } else if (language.includes("lv")) {
            this.resources = {strings:lvStrings, settings:lvSettings, images:{[appleStoreBadge]: lvAppleStoreBadge, [googleStoreBadge]: lvGoogleStoreBadge}}
        } else if (language.includes("nb")) {
            this.resources = {strings:nbStrings, settings:nbSettings, images:{[appleStoreBadge]: nbAppleStoreBadge, [googleStoreBadge]: nbGoogleStoreBadge}}
        } else if (language.includes("nl")) {
            this.resources = {strings:nlStrings, settings:nlSettings, images:{[appleStoreBadge]: nlAppleStoreBadge, [googleStoreBadge]: nlGoogleStoreBadge}}
        } else if (language.includes("pl")) {
            this.resources = {strings:plStrings, settings:plSettings, images:{[appleStoreBadge]: plAppleStoreBadge, [googleStoreBadge]: plGoogleStoreBadge}}
        } else if (language.includes("pt-PT")) {
            this.resources = {strings:ptPTStrings, settings:ptPTSettings, images:{[appleStoreBadge]: ptPTAppleStoreBadge, [googleStoreBadge]: ptPTGoogleStoreBadge}}
        } else if (language.includes("pt")) {
            this.resources = {strings:ptBRStrings, settings:ptBRSettings, images:{[appleStoreBadge]: ptBRAppleStoreBadge, [googleStoreBadge]: ptBRGoogleStoreBadge}}
        } else if (language.includes("ru")) {
            this.resources = {strings:ruStrings, settings:ruSettings, images:{[appleStoreBadge]: ruAppleStoreBadge, [googleStoreBadge]: ruGoogleStoreBadge}}
        } else if (language.includes("sk")) {
            this.resources = {strings:skStrings, settings:skSettings, images:{[appleStoreBadge]: skAppleStoreBadge, [googleStoreBadge]: skGoogleStoreBadge}}
        } else if (language.includes("sv")) {
            this.resources = {strings:svStrings, settings:svSettings, images:{[appleStoreBadge]: svAppleStoreBadge, [googleStoreBadge]: svGoogleStoreBadge}}
        } else if (language.includes("th")) {
            this.resources = {strings:thStrings, settings:thSettings, images:{[appleStoreBadge]: thAppleStoreBadge, [googleStoreBadge]: thGoogleStoreBadge}}
        } else if (language.includes("tr")) {
            this.resources = {strings:trStrings, settings:trSettings, images:{[appleStoreBadge]: trAppleStoreBadge, [googleStoreBadge]: trGoogleStoreBadge}}
        } else if (language.includes("zh-HK")) {
            this.resources = {strings:zhHKStrings, settings:zhHKSettings, images:{[appleStoreBadge]: zhHKAppleStoreBadge, [googleStoreBadge]: zhHKGoogleStoreBadge}}
        } else if (language.includes("zh-TW")) {
            this.resources = {strings:zhTWStrings, settings:zhTWSettings, images:{[appleStoreBadge]: zhTWAppleStoreBadge, [googleStoreBadge]: zhTWGoogleStoreBadge}}
        } else if (language.includes("zh")) {
            this.resources = {strings:zhCNStrings, settings:zhCNSettings, images:{[appleStoreBadge]: zhCNAppleStoreBadge, [googleStoreBadge]: zhCNGoogleStoreBadge}}
        } else {
            this.resources = {strings:enStrings, settings:enSettings, images:{[appleStoreBadge]: enAppleStoreBadge, [googleStoreBadge]: enGoogleStoreBadge}}
        }
    }

    getString(id) {
        return this.resources.strings[id];
    }
    
    getImage(id) {
        return this.resources.images[id];
    }

    getSetting(id) {
        return this.resources.settings[id];
    }
}

export default i18n;